import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import useEmblaCarousel from "embla-carousel-react";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import useDimensions from "react-cool-dimensions";
import Spinner from "./Spinner";
import LightboxImageSlider from "./LightboxImageSlider";
import { useAtom } from "jotai";
import { showLightboxAtom } from "../../jotai";

const ImageSlider = ({
  selectedSizeVariant,
  selectedPassepartoutVariant,
  selectedFrameVariant,
  dimension,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [showLightbox, setShowLightbox] = useAtom(showLightboxAtom);

  const [change, setChange] = useState(false);

  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({
    loop: true,
  });

  const [emblaGalleryRef, emblaGalleryApi] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true,
    axis: "y",
  });

  const onClick = useCallback(
    (index) => {
      if (!emblaMainApi || !emblaGalleryApi) return;

      emblaMainApi.scrollTo(index);
    },
    [emblaMainApi, emblaGalleryApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaGalleryApi) return;

    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    emblaGalleryApi.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaGalleryApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaMainApi) return;

    onSelect();

    emblaMainApi.on("reInit", onSelect);
    emblaMainApi.on("select", onSelect);
  }, [emblaMainApi, onSelect]);

  const scrollPrev = useCallback(() => {
    if (emblaMainApi) emblaMainApi.scrollPrev();
  }, [emblaMainApi]);

  const scrollNext = useCallback(() => {
    if (emblaMainApi) emblaMainApi.scrollNext();
  }, [emblaMainApi]);

  const [mainImageHeight, setMainImageHeight] = useState(0);

  const { observe, unobserve, width, height, entry } = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      setMainImageHeight(height);
    },
  });

  const getImageScale = (hasPassepartout, hasFrame, isThumbnail = false) => {
    if (productId === 14609 || productId === 195162) {
      if (isThumbnail) {
        return 0.75;
      } else {
        if (hasPassepartout && !hasFrame) {
          return 0.57;
        } else if (!hasPassepartout && hasFrame) {
          return 0.75;
        } else if (hasPassepartout && hasFrame) {
          return 0.57;
        } else {
          return 0.75;
        }
      }
    } else {
      if (isThumbnail) {
        return 0.75;
      } else {
        if (hasPassepartout && !hasFrame) {
          return 0.57;
        } else if (!hasPassepartout && hasFrame) {
          return 0.75;
        } else if (hasPassepartout && hasFrame) {
          return 0.57;
        } else {
          return 0.75;
        }
      }
    }
  };

  const getBoxShadowScaleY = (
    hasPassepartout,
    hasFrame,
    isThumbnail = false
  ) => {
    const testNewImageSize = false;
    if (testNewImageSize && (productId === 14609 || productId === 195162)) {
      if (isThumbnail) {
        return 0.75;
      } else {
        if (hasPassepartout) {
          return 0.74;
        } else {
          return 0.75;
        }
      }
    } else {
      if (isThumbnail) {
        return 0.75;
      } else {
        if (hasPassepartout) {
          if (dimension === "square") {
            return 0.732;
          } else {
            return 0.745;
          }
        } else {
          if (dimension === "landscape") {
            return 0.763;
          } else {
            return 0.75;
          }
        }
      }
    }
  };
  const getBoxShadowScaleX = (
    hasPassepartout,
    hasFrame,
    dimension,
    isThumbnail = false
  ) => {
    const testNewImageSize = false;
    if (testNewImageSize && (productId === 14609 || productId === 195162)) {
      if (isThumbnail) {
        return 0.75;
      } else {
        if (hasPassepartout) {
          return 0.74;
        } else {
          return 0.75;
        }
      }
    } else {
      if (isThumbnail) {
        return 0.75;
      } else {
        if (hasPassepartout) {
          if (dimension === "landscape") {
            return 0.743;
          } else if (dimension === "square") {
            return 0.732;
          } else {
            return 0.731;
          }
        } else {
          if (dimension === "landscape") {
            return 0.75;
          } else {
            return 0.75;
          }
        }
      }
    }
  };

  useEffect(() => {
    onClick(0);
  }, [
    selectedSizeVariant,
    selectedPassepartoutVariant?.desktopImageUrl,
    selectedFrameVariant?.frameImage,
  ]);

  useEffect(() => {
    setChange(true);

    setTimeout(() => {
      setChange(false);
    }, 1000);
  }, [
    JSON.stringify(selectedSizeVariant),
    JSON.stringify(selectedPassepartoutVariant),
    JSON.stringify(selectedFrameVariant),
  ]);

  console.log("singleProductImages", singleProductImages);

  let productImages;

  if (!isPassepartoutProduct && !isFrameProduct) {
    if (singleProductImages?.length > 1) {
      const [first, ...rest] = singleProductImages;
      console.log("productImages first and rest", first, rest);

      rest.unshift(rest.pop());

      productImages = rest;
    } else {
      productImages = singleProductImages;
    }
  } else {
    productImages = singleProductImages;
  }

  console.log("productImages", productImages);

  return (
    <>
      <motion.div
        className="embla-thumbs overflow-y-auto mr-3 shrink-0 relative hidden sm:block hide-scrollbar"
        animate={{ maxHeight: mainImageHeight }}
      >
        <div
          className="embla-thumbs__viewport relative w-[168px]"
          ref={emblaGalleryRef}
        >
          <div className="embla-thumbs__container flex flex-col gap-3">
            {productImages.map((productImage, index) => (
              <div
                key={index}
                className={clsx(
                  "embla-thumbs__slide flex-[0_0_100%] min-w-0 relative transition-opacity duration-300 ease-in-out cursor-pointer hover:opacity-100",
                  isLimitedEditionsProduct
                    ? "bg-[#ececec]"
                    : "bg-gradient-to-r from-[#f6f6f6] to-[#d9d9d9]",
                  productId === 14609 || productId === 195162
                    ? "aspect-[992/1389]"
                    : "aspect-[800/1100]",
                  selectedIndex === index ? "opacity-100" : "opacity-50",
                  0 === index &&
                    (dimension === "landscape" || dimension === "square") &&
                    "flex"
                )}
                onClick={() => onClick(index)}
              >
                {/* <img
                  className="embla-thumbs__slide__img block w-[168px] h-auto"
                  src={productImage?.gallery[0]}
                  width="271"
                  height="345"
                  alt=""
                /> */}
                <AnimatePresence>
                  {(selectedSizeVariant ||
                    selectedPassepartoutVariant?.passepartoutImage) &&
                    !isPassepartoutProduct &&
                    !isFrameProduct &&
                    !isTransparentArtPrintProduct &&
                    !isLimitedEditionsProduct &&
                    !isWallObjectsProduct && (
                      <motion.div
                        initial={{
                          opacity: 0,
                          scaleY: getBoxShadowScaleY(
                            selectedPassepartoutVariant?.desktopImageUrl,
                            selectedFrameVariant?.frameImage,
                            true
                          ),
                          scaleX: getBoxShadowScaleX(
                            selectedPassepartoutVariant?.desktopImageUrl,
                            selectedFrameVariant?.frameImage,
                            dimension,
                            true
                          ),
                          top:
                            dimension === "landscape" || dimension === "square"
                              ? "50%"
                              : "auto",
                          y:
                            dimension === "landscape" || dimension === "square"
                              ? "-50%"
                              : "0",
                        }}
                        animate={{
                          opacity: 1,
                          scaleY: getBoxShadowScaleY(
                            selectedPassepartoutVariant?.desktopImageUrl,
                            selectedFrameVariant?.frameImage,
                            true
                          ),
                          scaleX: getBoxShadowScaleX(
                            selectedPassepartoutVariant?.desktopImageUrl,
                            selectedFrameVariant?.frameImage,
                            dimension,
                            true
                          ),
                          top:
                            dimension === "landscape" || dimension === "square"
                              ? "50%"
                              : "auto",
                          y:
                            dimension === "landscape" || dimension === "square"
                              ? "-50%"
                              : "0",
                        }}
                        exit={{ opacity: 0 }}
                        style={{
                          boxShadow:
                            0 === index
                              ? "-2.4px 2.4px 5.2px 0px rgba(0,0,0,0.15)"
                              : "none",
                        }}
                        className={clsx(
                          "embla__slide__img block absolute left-0 w-full origin-center pointer-events-none",
                          dimension === "landscape"
                            ? "top-1/2 -translate-y-1/2"
                            : "top-0",
                          dimension === "portrait" &&
                            (productId === 14609 || productId === 195162) &&
                            "aspect-[992/1389]",
                          dimension === "portrait" &&
                            productId !== 14609 &&
                            productId !== 195162 &&
                            "aspect-[800/1100]",
                          dimension === "landscape" && "aspect-[1389/992]",
                          dimension === "square" && "aspect-square",
                          index !== 0 ? "hidden z-0" : "z-40"
                        )}
                      />
                    )}
                </AnimatePresence>
                <motion.img
                  key="thumbnail-image"
                  // layout
                  initial={{
                    scale:
                      index === 0 &&
                      !isPassepartoutProduct &&
                      !isFrameProduct &&
                      !isTransparentArtPrintProduct &&
                      !isLimitedEditionsProduct &&
                      !isWallObjectsProduct
                        ? getImageScale(
                            selectedPassepartoutVariant?.desktopImageUrl,
                            selectedFrameVariant?.frameImage,
                            true
                          )
                        : 1,
                  }}
                  animate={{
                    scale:
                      index === 0 &&
                      !isPassepartoutProduct &&
                      !isFrameProduct &&
                      !isTransparentArtPrintProduct &&
                      !isLimitedEditionsProduct &&
                      !isWallObjectsProduct
                        ? getImageScale(
                            selectedPassepartoutVariant?.desktopImageUrl,
                            selectedFrameVariant?.frameImage,
                            true
                          )
                        : 1,
                  }}
                  className={clsx(
                    "embla__slide__img block origin-center",
                    index === 0 &&
                      (dimension === "landscape" || dimension === "square") &&
                      "self-center"
                  )}
                  src={productImage?.gallery[0]}
                  width={271}
                  height={345}
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
      </motion.div>
      <div className="flex flex-col">
        <div
          className={clsx(
            "relative",
            0 === selectedIndex &&
              (dimension === "landscape" || dimension === "square") &&
              "flex"
          )}
        >
          {/* <AnimatePresence>
            {change && (
              <motion.div
                key={change}
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 mix-blend-exclusion z-40"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Spinner />
              </motion.div>
            )}
          </AnimatePresence> */}
          <div className="embla overflow-hidden cursor-zoom-in" ref={observe}>
            <div className="embla__viewport relative" ref={emblaMainRef}>
              <div className="embla__container flex">
                {productImages.map((productImage, index) => (
                  <div
                    key={index}
                    className={clsx(
                      "embla__slide flex-[0_0_100%] min-w-0 relative z-40",
                      isLimitedEditionsProduct
                        ? "bg-[#ececec]"
                        : "bg-gradient-to-r from-[#f6f6f6] to-[#d9d9d9]",
                      0 === index &&
                        (dimension === "landscape" || dimension === "square") &&
                        "flex"
                    )}
                    onClick={() => setShowLightbox(true)}
                  >
                    {console.log(
                      "CHECK for SPECIAL HANDLING",
                      index === 0 &&
                        !isPassepartoutProduct &&
                        !isFrameProduct &&
                        !isTransparentArtPrintProduct &&
                        !isLimitedEditionsProduct &&
                        !isWallObjectsProduct
                    )}
                    <AnimatePresence>
                      {(selectedSizeVariant ||
                        selectedPassepartoutVariant?.passepartoutImage) &&
                        !selectedFrameVariant?.frameImage &&
                        !isPassepartoutProduct &&
                        !isFrameProduct &&
                        !isTransparentArtPrintProduct &&
                        !isLimitedEditionsProduct &&
                        !isWallObjectsProduct && (
                          <motion.div
                            initial={{
                              opacity: 0,
                              scaleY: getBoxShadowScaleY(
                                selectedPassepartoutVariant?.desktopImageUrl,
                                selectedFrameVariant?.frameImage
                              ),
                              scaleX: getBoxShadowScaleX(
                                selectedPassepartoutVariant?.desktopImageUrl,
                                selectedFrameVariant?.frameImage,
                                dimension
                              ),
                              top:
                                dimension === "landscape" ||
                                dimension === "square"
                                  ? "50%"
                                  : "auto",
                              y:
                                dimension === "landscape" ||
                                dimension === "square"
                                  ? "-50%"
                                  : "0",
                            }}
                            animate={{
                              opacity: 1,
                              scaleY: getBoxShadowScaleY(
                                selectedPassepartoutVariant?.desktopImageUrl,
                                selectedFrameVariant?.frameImage
                              ),
                              scaleX: getBoxShadowScaleX(
                                selectedPassepartoutVariant?.desktopImageUrl,
                                selectedFrameVariant?.frameImage,
                                dimension
                              ),
                              top:
                                dimension === "landscape" ||
                                dimension === "square"
                                  ? "50%"
                                  : "auto",
                              y:
                                dimension === "landscape" ||
                                dimension === "square"
                                  ? "-50%"
                                  : "0",
                            }}
                            exit={{ opacity: 0 }}
                            style={{
                              boxShadow:
                                0 === index
                                  ? "-2.4px 2.4px 5.2px 0px rgba(0,0,0,0.15)"
                                  : "none",
                            }}
                            className={clsx(
                              "embla__slide__img block absolute left-0 w-full origin-center pointer-events-none",
                              dimension === "landscape"
                                ? "top-1/2 -translate-y-1/2"
                                : "top-0",
                              (productId === 14609 || productId === 195162) &&
                                dimension === "portrait" &&
                                "aspect-[992/1389]",
                              productId !== 14609 &&
                                productId !== 195162 &&
                                dimension === "portrait" &&
                                "aspect-[800/1100]",
                              dimension === "landscape" && "aspect-[1389/992]",
                              dimension === "square" && "aspect-square",
                              index !== 0 ? "hidden z-0" : "z-40"
                            )}
                          />
                        )}
                    </AnimatePresence>
                    <motion.img
                      key="main-image"
                      // layout
                      initial={{
                        scale:
                          index === 0 &&
                          !isPassepartoutProduct &&
                          !isFrameProduct &&
                          !isTransparentArtPrintProduct &&
                          !isLimitedEditionsProduct &&
                          !isWallObjectsProduct
                            ? getImageScale(
                                selectedPassepartoutVariant?.desktopImageUrl,
                                selectedFrameVariant?.frameImage
                              )
                            : 1,
                      }}
                      animate={{
                        scale:
                          index === 0 &&
                          !isPassepartoutProduct &&
                          !isFrameProduct &&
                          !isTransparentArtPrintProduct &&
                          !isLimitedEditionsProduct &&
                          !isWallObjectsProduct
                            ? getImageScale(
                                selectedPassepartoutVariant?.desktopImageUrl,
                                selectedFrameVariant?.frameImage
                              )
                            : 1,
                      }}
                      className={clsx(
                        "embla__slide__img block origin-center",
                        index === 0 &&
                          (dimension === "landscape" ||
                            dimension === "square") &&
                          "self-center"
                      )}
                      src={productImage?.main[0]}
                      width={
                        productId === 14609 || productId === 195162 ? 992 : 800
                      }
                      height={
                        productId === 14609 || productId === 195162
                          ? 1389
                          : 1100
                      }
                      alt=""
                    />
                    <AnimatePresence>
                      {selectedPassepartoutVariant?.passepartoutImage &&
                        !isPassepartoutProduct &&
                        !isFrameProduct &&
                        !isTransparentArtPrintProduct &&
                        !isLimitedEditionsProduct &&
                        !isWallObjectsProduct && (
                          <motion.img
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{
                              // duration: 0.1,
                              type: "tween",
                            }}
                            className={clsx(
                              "embla__slide__img block absolute left-0 z-40",
                              dimension === "landscape"
                                ? "top-1/2 -translate-y-1/2"
                                : "top-0",
                              index !== 0 ? "hidden z-0" : "z-40"
                            )}
                            src={selectedPassepartoutVariant.passepartoutImage}
                            width={
                              productId === 14609 || productId === 195162
                                ? 992
                                : 800
                            }
                            height={
                              productId === 14609 || productId === 195162
                                ? 1389
                                : 1100
                            }
                            alt=""
                          />
                        )}
                    </AnimatePresence>
                    <AnimatePresence>
                      {selectedFrameVariant?.frameImage &&
                        !isPassepartoutProduct &&
                        !isFrameProduct &&
                        !isTransparentArtPrintProduct &&
                        !isLimitedEditionsProduct &&
                        !isWallObjectsProduct && (
                          <motion.img
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{
                              // duration: 0.1,
                              type: "tween",
                            }}
                            className={clsx(
                              "embla__slide__img block absolute left-0 z-40",
                              dimension === "landscape"
                                ? "top-1/2 -translate-y-1/2"
                                : "top-0",
                              index !== 0 ? "hidden z-0" : "z-40"
                            )}
                            src={selectedFrameVariant.frameImage}
                            width={
                              productId === 14609 || productId === 195162
                                ? 992
                                : 800
                            }
                            height={
                              productId === 14609 || productId === 195162
                                ? 1389
                                : 1100
                            }
                            alt=""
                          />
                        )}
                    </AnimatePresence>
                  </div>
                ))}
              </div>
              {/* <button
                type="button"
                className="embla__prev w-8 h-8 bg-tpc-light rounded-full absolute top-1/2 -translate-y-1/2 left-3 hidden sm:block z-50"
                onClick={scrollPrev}
              >
                <FontAwesomeIcon icon={["fal", "arrow-left"]} />
              </button>
              <button
                type="button"
                className="embla__next w-8 h-8 bg-tpc-light rounded-full absolute top-1/2 -translate-y-1/2 right-3 hidden sm:block z-50"
                onClick={scrollNext}
              >
                <FontAwesomeIcon icon={["fal", "arrow-right"]} />
              </button> */}
            </div>
          </div>
        </div>
        <div className="embla__dots flex gap-4 justify-center mt-[18px] sm:hidden">
          {productImages.map((_, index) => (
            <div
              key={index}
              onClick={() => onClick(index)}
              className={clsx(
                "w-2 h-2 rounded-full",
                index === selectedIndex ? "bg-black" : "bg-tpc-darker"
              )}
            />
          ))}
        </div>
      </div>
      <AnimatePresence>
        {showLightbox && (
          <motion.div
            key="lightbox"
            className="fixed top-0 left-0 w-screen h-[100dvh] bg-white bg-opacity-90 z-[9999]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LightboxImageSlider
              selectedSizeVariant={selectedSizeVariant}
              selectedPassepartoutVariant={selectedPassepartoutVariant}
              selectedFrameVariant={selectedFrameVariant}
              dimension={dimension}
              selectedIndexFromParent={selectedIndex}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default ImageSlider;
